import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SCHNEIDER_ELECTRIC_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { useGetSiteSwitches } from '../../scheduler/schedulerApi';
import { useGetSiteSolarSpongeConfigQuery } from '../../site/control-profile/siteSolarSpongeConfigApi';
import { selectSite } from '../../site/siteSlice';
import { updateSmartSaveDevices, updateSmartSaveStatus } from './smartSaveSlice';
import { useSmartSaveStatus } from './use-smartsave-status';

/**
 * This hook is used to sync the smart save devices to redux
 * It ensures when changes are made to the smart save devices, the redux store is synced
 * @returns {isLoaded} - boolean indicating if all required data is loaded
 */

export const useSyncSmartSaveSettingsToRedux = () => {
  const { site_id: siteId, devices } = useSelector(selectSite);
  const { smartSaveStatus } = useSmartSaveStatus();
  const {
    data: siteSmartSaveConfig,
    isLoading: isSiteSmartSaveConfigLoading,
    isFetching: isFetchingSiteSmartSaveConfig,
  } = useGetSiteSolarSpongeConfigQuery(siteId);
  const { switches, isSwitchesLoading } = useGetSiteSwitches();
  const isLoaded = !isSiteSmartSaveConfigLoading && !isSwitchesLoading;
  const isSynced = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const isLoadedAndNotSynced = isLoaded && !isSynced.current;
    const isFetchedAndLoaded = isLoaded && !isFetchingSiteSmartSaveConfig;

    if (isLoadedAndNotSynced || isFetchedAndLoaded) {
      const registeredSmartSaveDevices = siteSmartSaveConfig?.solar_sponge_devices ?? [];
      const registeredSmartSaveDeviceIds = registeredSmartSaveDevices.map((device) => device.site_device_id);

      const notRegisteredEVChargers = devices.filter(
        (device) =>
          device.device_type === 'EV_CHARGER' &&
          device.manufacturer_id === SCHNEIDER_ELECTRIC_MANUFACTURER_ID &&
          !registeredSmartSaveDeviceIds.includes(device.id)
      );

      const notRegisteredHotWaterDevices = switches.filter(
        (s) =>
          s.appliance_label?.includes('load_hot_water') &&
          !s.appliance_label?.includes('load_hot_water_heat_pump') &&
          !registeredSmartSaveDeviceIds.includes(s.id)
      );

      const newDevices = [...notRegisteredEVChargers, ...notRegisteredHotWaterDevices].map((device, index) => ({
        site_device_id: device.id,
        active: false,
        priority: registeredSmartSaveDevices.length + index + 1,
      }));

      const smartSaveDevices = [...registeredSmartSaveDevices, ...newDevices];

      dispatch(updateSmartSaveDevices(smartSaveDevices));
      dispatch(updateSmartSaveStatus(smartSaveStatus));
      isSynced.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, isLoaded, siteId, siteSmartSaveConfig, dispatch, smartSaveStatus, isFetchingSiteSmartSaveConfig]);

  return {
    isLoaded,
    isFetchingSiteSmartSaveConfig,
  };
};
