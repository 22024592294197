/// <reference types="vite/client" />

import { Retailer } from 'clipsal-cortex-types/src/api';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import packageJson from '../../package.json';
import { ENV_TYPE_SHORTHAND } from '../env-type';
import { REGION_SHORTHAND } from '../region-type';

export const APPLE_APP_STORE_URL = 'https://apps.apple.com/au/app/clipsal-solar-pulse/id1488273491';
export const ANDROID_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.clipsalsolar.pulse';
export const PRIVACY_POLICY_URL = 'https://clipsalsolar.com/privacy-policy/';

export const PAGE_SPACING_PER_VIEWPORT = [1, 3, 20, 20, '20vw'];

export const SUPPORT_EMAIL = 'support@clipsalcortex.com';

export const BOTTOM_NAV_HEIGHT = 'calc(58px + env(safe-area-inset-bottom))';

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const HOME_PAGE_ROUTE = 'live';

export const ENDPOINT =
  localStorage.getItem('customEndpoint') ||
  (import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_ENDPOINT`] as string);
export const USER_POOL_ID =
  localStorage.getItem('customCognitoUserPoolId') ||
  (import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_COGNITO_USER_POOL_ID`] as string);
export const CLIENT_ID =
  localStorage.getItem('customCognitoUserPoolClientId') ||
  (import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_CLIENT_ID`] as string);

// Need to include cortexEnvType in the evaluation because capacitor builds are always in production mode
export const IS_PRODUCTION =
  import.meta.env?.MODE === 'production' && localStorage.getItem('cortexEnvType') !== 'STAGING';
export const IS_NOT_DEVELOPMENT = import.meta.env?.MODE !== 'development';
export const APP_VERSION = packageJson.version;

export const SIDENAV_WIDTH = '250px';

export const COMMON_TOUR_STEP_PROPS = {
  disableBeacon: true,
  disableOverlay: true,
};

// Top banners are always 32px in height
export const TOP_BANNER_HEIGHT = '32px';
export const TOP_BANNER_SPACING_AFFORDANCE = 'calc(32px + env(safe-area-inset-top))';

export const IS_DEMO_LOGIN = localStorage.getItem('isDemoLogin') === 'true';

let MOBILE_COMPONENT_HEIGHT = 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 16px)';
if (IS_DEMO_LOGIN) MOBILE_COMPONENT_HEIGHT = `calc(${MOBILE_COMPONENT_HEIGHT} - ${TOP_BANNER_HEIGHT})`;
export { MOBILE_COMPONENT_HEIGHT };
export const MOBILE_COMPONENT_HEIGHT_EXCLUDING_NAV = `calc(${MOBILE_COMPONENT_HEIGHT} - ${TOP_NAV_SPACING_AFFORDANCE})`;

// 72px is the margin above and below the content card on desktop (56+16)
export const DESKTOP_COMPONENT_HEIGHT = `calc(${MOBILE_COMPONENT_HEIGHT} - 56px)`;

export const IS_RUNNING_CYPRESS_TESTS = !!window.Cypress;
export const IS_RUNNING_HEADLESS =
  /HeadlessChrome/.test(window.navigator.userAgent) || /headless/i.test(navigator.appVersion);

export const IS_PRODUCTION_BUILD = IS_PRODUCTION && !IS_RUNNING_CYPRESS_TESTS && !IS_RUNNING_HEADLESS;

export const AMBER_RETAILER_ID = 36;
export const REAL_TIME_RETAILER_IDS = [AMBER_RETAILER_ID];

export const EMPTY_RETAILER: Retailer = {
  id: 0,
  name: 'Other Retailer',
  url: '',
  country_code: 'AU',
};
